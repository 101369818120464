// Example:
// {
//   id: 'example-feature',
//   name: 'Example',
//   checked: false,
//   description: 'Example description'
// }

// DO NOT REMOVE `as const`!
export const trunkFeatures = [
  {
    id: 'c-masqueraded',
    name: 'Masqueraded',
    checked: false,
    description: 'Simulate masqueraded session',
    isMasquerade: true,
  },
  {
    id: 'pos-de-tss',
    name: 'Pos DE TSS',
    checked: false,
    description: 'Tracking integration for POS DE TSS',
  },
  {
    id: 'CAR-1985-client-app-appvocacy-setting',
    name: 'CAR-1985-client-app-appvocacy-setting',
    checked: false,
  },
  {
    id: 'CAR-2635-client-forms-page',
    name: 'CAR-2635-client-forms-page',
    checked: false,
  },
  {
    id: 'webview-testing',
    name: 'webview-testing',
    checked: false,
  },
  {
    id: 'automated-messaging-birthday-campaign',
    name: 'automated-messaging-birthday-campaign',
    checked: false,
  },
  {
    id: 'CD-604-connect-enable-phone-number-references',
    name: 'CD-604-connect-enable-phone-number-references',
    checked: false,
  },
  {
    id: 'CD-661-show-consultation-forms-list',
    name: 'CD-661-show-consultation-forms-list',
    checked: false,
  },
  {
    id: 'CD-695-enable-add-multiple-images',
    name: 'CD-695-enable-add-multiple-images',
    checked: false,
  },
  {
    id: 'Store-on-Connect',
    name: 'Store-on-Connect',
    checked: false,
  },
  {
    id: 'CD-823-salesforce-bot',
    name: 'CD-823-salesforce-bot',
    checked: false,
  },
  {
    id: 'CD-998-allow-edit-venue-details-and-photos',
    name: 'CD-998-allow-edit-venue-details-and-photos',
    checked: false,
  },
  {
    id: 'CD-1127-DATEV-export',
    name: 'CD-1127-DATEV-export',
    checked: false,
  },
  {
    id: 'CD-1415-new-Bank-Details-page',
    name: 'CD-1415-new-Bank-Details-page',
    checked: false,
  },
  {
    id: 'CD-1312-salesforce-bot-secure-authentication',
    name: 'CD-1312-salesforce-bot-secure-authentication',
    checked: false,
  },
  {
    id: 'CPG-63-self-onboarding-no-go-live',
    name: 'CPG-63-self-onboarding-no-go-live',
    checked: false,
  },
] as const;
