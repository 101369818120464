import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { Reconnect } from 'federation/Reconnect';
import { track } from 'src/tracking/trackingService';
import { getOrAppendChild } from 'utilities/DOM/getOrAppendChild';

export const OnboardingInitializer = (): Initializer => {
  const node = getOrAppendChild({ id: 'onboarding', parentNode: document.body });

  const destroy = () => ReactDOM.unmountComponentAtNode(node);

  const render = () => {
    destroy();
    ReactDOM.render(
      <Reconnect
        name="Onboarding"
        props={{
          notifyFn: Wahanda.ToastNotifications.sendMessage,
          trackingService: {
            trackEvent: ({ name, payload }) => track(name, payload),
          },
        }}
      />,
      node,
    );
  };

  return { destroy, render };
};
