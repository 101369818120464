import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { Reconnect } from 'federation/Reconnect';
import { L1Page } from 'common/tracking/navigation';
import { getOrAppendChild } from 'utilities/DOM/getOrAppendChild';

export const SetupProgressInitializer = (): Initializer => {
  const node = getOrAppendChild({ id: 'setup-progress', parentNode: document.body });

  const destroy = () => ReactDOM.unmountComponentAtNode(node);

  const render = () => {
    destroy();
    ReactDOM.render(
      <Reconnect name="SetupProgress" props={{ onContinue: redirectToDashboard }} />,
      node,
    );
  };

  return { destroy, render };
};

const redirectToDashboard = () => {
  window.location = Wahanda.Url.getFullUrl(L1Page.DASHBOARD);
};
