/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* global BackboneEx _ */
import { onStateChange as onWebSocketsStateChange } from 'common/websockets';
import channelCodes from 'config/channelCodes';
import { CalendarAnalytics, enableRecording, trackEvent } from 'common/analytics';
import App from 'common/backbone-app';
import moment from '../../../javascripts-es6/common/moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Reconnect } from 'federation/Reconnect';

/**
 * Calendar main view.
 */
App.Views.Calendar = BackboneEx.View.Main.extend({
  events: {
    'select.menu #calendar-type-choice': 'onTypeSelect',
    'click .redeem': 'showVoucherRedemptionDialog',
    'click .checkout': 'openNewCheckout',
    'click .a-set-availability': 'showSetAvailabilityDialog',
    'click .a-close-out': 'closeOutSpaDays',
    'click .calendar-resource li': 'onResourceChange',
    'click #keyboard-shortcuts-info': () => {
      App.Views.Calendar.KeyboardShortcuts.show();
    },
    'click #calendar-wl-entries-more': 'showWaitingListEntries',
  },

  allTypeClasses: 'type-spa-day type-spa-break type-appointment',
  calendarType: null,
  wizardDone: false,
  animationSpeed: 300,
  navigationInputAccepted: true,

  initialize: function initialize() {
    enableRecording();

    const self = this;
    this.setupOptions();

    App.trigger(Wahanda.Event.CALENDAR_VIEW);

    // Events
    App.on(Wahanda.Event.CALENDAR_ALLOWED_TYPES, (list) => {
      self.checkCurrentCalendarType(list);
    });
    App.on(Wahanda.Event.CALENDAR_DRAWER_CLOSE, () => {
      self.closeWaitingList();
    });
    App.on(Wahanda.Event.CALENDAR_OPEN_APPOINTMENT_MODAL, (data) => {
      self.openCreateAppointmentModal(data);
    });
    App.on(Wahanda.Event.CALENDAR_REFETCH_WAITINGLISTS, () => {
      self.fetchWaitingListEntries(self.appointmentCalendar.getDate());
    });

    // Appointment calendar + it's subviews
    this.appointmentCalendar = new App.Views.Calendar.AppointmentCalendar({
      el: this.$el,
      options: this.options.options,
      mainView: this,
    });
    // Dated services calendar
    this.datedCalendar = new App.Views.Calendar.DatetableCalendar({
      el: this.$('#dated-calendar'),
      date: this.options.options.initialDate,
      mainView: this,
      closeOutButton: this.$('.close-out-box'),
      initialResourceId: /^spa/.test(this.getCalendarType())
        ? this.options.options.initialResourceId
        : null,
    });
    // Dated month picker
    this.monthPicker = new App.Views.Calendar.MonthPicker({
      el: this.$('#month-calendar'),
      date: this.options.options.initialDate,
    });
    // Appt / spa-day / spa-break select
    this.typeSelector = new App.Views.Calendar.CalendarTypeSelect({
      el: this.$('#calendar-type-choice'),
    });

    this.datedAlertsView = new App.Views.Calendar.DatedAlerts({
      el: this.$('#calendar-alerts'),
    });

    this.hideNonWorkingToggle = new App.Views.Calendar.HideNonWorkingToggle({
      el: this.$('.js-column-collapse-button'),
      options: this.options.options,
      initialCalendarType: this.options.options.initialCalendarType,
      isDayView: this.appointmentCalendar.isDayView.bind(this.appointmentCalendar),
    });

    this.typeSelector.on('rendered', () => {
      // Selects the default type when type selector has finished rendering
      // The "initial" parameter means that this is not a user triggered change
      self.changeType(self.getCalendarType(), { initial: true });
      self.$el.removeClass('loading');
    });

    this.setupShortcuts();

    if (!App.isRestrictedMode()) {
      App.headerView.setModule('calendar');
      // Start calendar refresh timer
      this.setupRefreshTimer();
    }
    this.setupSwipeGestures();

    $('body').addClass('dynamic-allocation');
  },

  setupRefreshTimer: function setupRefreshTimer() {
    const TIMER_NAME = 'calendar-refresh';

    const changeInterval = (intervalType) => {
      App.Timer.cancel(TIMER_NAME);

      const pollingInterval = App.config.getPollingInterval(intervalType) * 60 * 1000;
      App.Timer.start(TIMER_NAME, pollingInterval);
    };

    onWebSocketsStateChange(
      () => changeInterval('pushEnabled'),
      () => changeInterval('default'),
    );

    // Start with the default interval
    changeInterval('default');
  },

  setupOptions: function setupOptions() {
    const opts = _.clone(this.options.options || {});
    // Date configuration
    if (opts.initialDate) {
      try {
        opts.initialDate = Wahanda.Date.createDate(opts.initialDate);
      } catch (e) {
        console.error(e);
      }
    } else {
      opts.initialDate = Wahanda.Date.createVenueDate();
    }
    if (!Wahanda.Date.isValidDate(opts.initialDate)) {
      opts.initialDate = Wahanda.Date.createVenueDate();
    }
    // Initial type
    this.calendarType = opts.initialCalendarType || null;

    this.options.options = opts;

    // Clean data from mainViewOptions
    this.cleanMainViewOptions();
  },

  cleanMainViewOptions: function cleanMainViewOptions() {
    App.mainViewOptions = _.extend(App.mainViewOptions || {}, {
      initialCalendarType: null,
      initialDate: null,
      initialResourceId: null,
    });
  },

  setupSwipeGestures: function setupSwipeGestures() {
    if (!Wahanda.Util.isTouchDevice()) {
      return;
    }

    const $swipeTarget = this.$('.section-main');
    $swipeTarget.swipeArrows({
      scrollElement: '.wc-scrollable-grid',
      onSwipe: (direction) => {
        const event = Wahanda.Event[direction === 'left' ? 'CALENDAR_GO_PREV' : 'CALENDAR_GO_NEXT'];
        App.trigger(event);
        App.trigger(Wahanda.Event.CALENDAR_SWIPE);
      },
    });

    App.on(Wahanda.Event.APPOINTMENT_DRAG_STARTED, () => {
      $swipeTarget.swipeArrows('pause');
    }).on(Wahanda.Event.APPOINTMENT_DRAG_ENDED, () => {
      $swipeTarget.swipeArrows('resume');
    });
  },

  render: function render() {
    this.injectOptionsFromRouter();

    if (!App.config.canSellDated() && this.getCalendarType() !== 'appointment') {
      // Change calendar type to appointment, b/c this venue is in can't sell dated stuff
      this.changeType('appointment');
    }

    this.typeSelector.render();
    this.monthPicker.render();
    if (!App.config.get('venue').pointOfSaleEnabled) {
      this.renderPosLiteSidebarButtons();
    } else {
      this.destroyPosLiteSidebarButtons();
    }
    this.renderIdentityVerificationDialog();
    this.renderDropdowns();
    this.renderPOSAdvertisement();
    this.renderSidebarInfo();
    this.openDialogFromUrl();
    App.setUpGoLive();

    if (Wahanda.Features.isEnabled('THY-waiting-list-mvp-enable')) {
      this.fetchWaitingListEntries(this.options.options.initialDate);

      App.on(Wahanda.Event.CALENDAR_DISPLAY_TYPE_CHANGE, ({ type }) => {
        if (type === 'day') {
          this.fetchWaitingListEntries(this.appointmentCalendar.getDate());
        }
      });

      const self = this;
      App.on('calendar:date-change', function (date) {
        self.fetchWaitingListEntries(date);
      });
    }
  },
  openCreateAppointmentModal(data) {
    const model = new App.Models.Appointment({
      appointmentDate: data.appointmentDate,
      startTime: data.startTime,
      endTime: data.endTime,
      venueId: data.venueId,
      venueCustomerId: data.venueCustomer.id,
      offerId: data.serviceId,
      skus: [{ skuId: data.optionId }],
    });

    const customerData = data.venueCustomer;

    App.ES6.Initializers.State.change({
      'calendar-event-editor': {
        appointmentViewData: {
          model,
        },
        tab: 'appointment',
        customerData,
      },
    });
  },
  fetchWaitingListEntries(date) {
    if (!this.appointmentCalendar.isDayView) {
      return;
    }

    const venueId = App.getVenueId();

    fetch(
      `/api/calendar/venue/${venueId}/waiting-list-entry?date=${Wahanda.Date.toApiString(date)}`,
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error fetching waiting list entries');
        }

        return response.json();
      })
      .then((data) => {
        const count = data.length;

        this.$('.calendar-pane').toggleClass('has-waiting-list', !!count);
        if (count) {
          this.$('#calendar-waiting-list-amount').text(
            count === 1
              ? Wahanda.lang.calendar.waitingList.oneRegistration.replace('{{count}}', count)
              : Wahanda.lang.calendar.waitingList.moreRegistrations.replace('{{count}}', count),
          );
        }
      });
  },
  showWaitingListEntries() {
    this.showWaitingList('overview');
  },
  showWaitingList(initialPage) {
    this.mountWaitingList(initialPage);
    this.$('#calendar-drawer').removeClass('hidden');
  },
  closeWaitingList() {
    this.unmountWaitingList();
    this.$('#calendar-drawer').addClass('hidden');
  },
  mountWaitingList(initialPage) {
    const initialDate = Wahanda.Date.formatToApiFullDate(this.appointmentCalendar.getDate()).date;
    const node = this.$('[data-reconnect-id="waiting-list"]').get(0);
    ReactDOM.render(
      <Reconnect
        name="WaitingList"
        props={{
          initialPage,
          initialDate,
        }}
      />,
      node,
    );
  },
  unmountWaitingList() {
    const node = this.$('[data-reconnect-id="waiting-list"]').get(0);
    ReactDOM.unmountComponentAtNode(node);
  },
  renderIdentityVerificationDialog: function renderIdentityVerificationDialog() {
    const isNotKycNeeded = !App.isIdentityVerificationNeeded();
    const isSelfOnboarding = App.isSelfOnboarding();
    const hasNotElapsedDay = !this.hasIdentityVerificationElapsedDay();

    // Self onboarding already has a KYC step
    if (isNotKycNeeded || hasNotElapsedDay || isSelfOnboarding) {
      return;
    }

    this.setIdentityVerificationLastShownDate();

    App.ES6.Initializers.IdentityVerificationModal({
      isKycNewSupplier: App.isKycNewSupplier(),
      node: this.$('.identity-verification-dialog')[0],
    }).render({});
  },
  setIdentityVerificationLastShownDate: () => {
    const isTestEnvironment = App.env === 'TEST';
    if (isTestEnvironment) {
      return;
    }

    const now = moment().startOf('day');
    Wahanda.LocalStorage.set(
      'calendar.identityVerification.lastShownDate',
      now.valueOf().toString(),
    );
  },
  hasIdentityVerificationElapsedDay: () => {
    const lastShownDateStr = Wahanda.LocalStorage.get(
      'calendar.identityVerification.lastShownDate',
    );
    const lastShownDate = moment(+lastShownDateStr ?? 0);
    const now = moment().startOf('day');
    const hasElapsedDay = lastShownDate.startOf('day').diff(now, 'days') !== 0;

    return hasElapsedDay;
  },
  createCurrentCalEvent: function createCurrentCalEvent() {
    const apptCalendar = this.appointmentCalendar;
    const now = App.ES6.moment.venueTimeZoneDate();
    const blockStart = App.ES6.moment(this.appointmentCalendar.getDate());

    // Use the Calendar's date, but current time.
    blockStart.hour(now.hour());
    // Align to 5 minute time grid
    blockStart.minutes(now.minutes() - (now.minutes() % 5));

    return {
      start: blockStart.toDate(),
      end: App.ES6.moment(blockStart).add(1, 'hour').toDate(),
      rid: apptCalendar.isDayView() ? null : this.appointmentCalendar.getDefaultResourceId(),
    };
  },

  startMultipleBlocking: function startMultipleBlocking() {
    const enterBlockoutMode = function () {
      this.navigationInputAccepted = false;
      this.appointmentCalendar.enterBlockoutMode();
    }.bind(this);
    const exitBlockoutMode = function () {
      this.navigationInputAccepted = true;
      this.appointmentCalendar.leaveBlockoutMode();
    }.bind(this);

    App.ES6.Initializers.State.change({
      'multi-blockout-mode': {
        onClose: function (data) {
          exitBlockoutMode();

          if (data && data.saved > 0) {
            this.appointmentCalendar.refreshMainCalendar();
            App.trigger(Wahanda.Event.CALENDAR_MULTIPLE_BLOCKS_SAVED, {
              count: data.count,
              savedIds: data.savedIds,
            });
          } else {
            App.trigger(Wahanda.Event.CALENDAR_MULTIPLE_BLOCKS_CANCEL);
          }
        }.bind(this),
        getBlockedValues: function () {
          return this.appointmentCalendar.getBlockoutEvents();
        }.bind(this),
        toggleSpinner: function (show) {
          const method = show ? 'showLoader' : 'hideLoader';

          this.appointmentCalendar[method]();
        }.bind(this),
      },
    });

    enterBlockoutMode();
    App.trigger(Wahanda.Event.CALENDAR_MULTIPLE_BLOCKS_OPENED);
  },

  prepareForFloatingEventMode() {
    this.floatingEventMode = true;
    this.$('#keyboard-shortcuts-info').wHide();
    this.$('#sidebar-info').wHide();
    this.$('.calendar-buttons, #calendar-alerts, #calendar-type-choice').addClass(
      'reschedule-mode',
    );
  },

  exitFloatingEventMode() {
    this.floatingEventMode = false;
    this.$('#keyboard-shortcuts-info').wShow();
    this.$('#sidebar-info').wShow();
    this.$('.calendar-buttons, #calendar-alerts, #calendar-type-choice').removeClass(
      'reschedule-mode',
    );
  },

  startReschedulingMode(itemList, data) {
    this.prepareForFloatingEventMode();
    this.appointmentCalendar.startReschedulingMode(itemList, data);
  },

  startRebookingMode(dayCollection, data) {
    this.prepareForFloatingEventMode();
    this.appointmentCalendar.startRebookingMode(dayCollection, data);
  },

  renderPosLiteSidebarButtons() {
    const node = this.$('.calendar-react-buttons')[0];

    App.ES6.Initializers.CheckoutButton({
      node,
      onCheckoutButtonClick: () => {
        CalendarAnalytics.trackPosLiteStandaloneProductCheckoutClick();
        const dialog = new App.Views.Forms.POSDialogLite();
        dialog.render();
        dialog.disablePOSSubmit();
        dialog.open();
      },
    }).render();
  },

  destroyPosLiteSidebarButtons() {
    const node = this.$('.calendar-react-buttons')[0];
    App.ES6.Initializers.CheckoutButton({ node }).destroy();
  },

  renderDropdowns() {
    const self = this;
    const node = this.$('.calendar-react-dropdowns')[0];

    if (this.getCalendarType() === 'appointment') {
      const apptButtonAllowed = this.appointmentCalendar.getCanShowAddAppointmentButton();

      App.ES6.Initializers.CalendarDropdowns.initialize(node, {
        addActions: {
          addAppointment: apptButtonAllowed
            ? function () {
                self.appointmentCalendar.openAddAppointmentDialog();
              }
            : null,
          addBlock() {
            // The param is a mock calEvent object
            self.appointmentCalendar.showBlockAddDialog(self.createCurrentCalEvent());
          },
          startMultipleBlocking: this.startMultipleBlocking.bind(this),
          redeemEvoucher() {
            self.appointmentCalendar.showUseEvoucherDialog(self.createCurrentCalEvent());
          },
          addWaitingList() {
            self.showWaitingList('create');
          },
        },
      });
    } else {
      App.ES6.ReactDOM.unmountComponentAtNode(node);
    }
  },

  renderPOSAdvertisement() {
    const venueChannelCode = App.config.get('venue').contentChannelCode;
    const countriesThatHavePOSEnabled = [
      channelCodes.BE,
      channelCodes.CH,
      channelCodes.DE,
      channelCodes.ES,
      channelCodes.GB,
      channelCodes.IE,
      channelCodes.IT,
      channelCodes.NL,
    ];
    const countriesThatWillHavePOSEnabledSoon = [channelCodes.FR, channelCodes.AT];

    const venueCanHavePosEnabled = [
      ...countriesThatHavePOSEnabled,
      ...countriesThatWillHavePOSEnabledSoon,
    ].some((channelCode) => channelCode === venueChannelCode);
    const showPOSAd = !App.config.get('venue').pointOfSaleEnabled && venueCanHavePosEnabled;
    const wrapperDOM = this.$('#pos-ad');
    if (!showPOSAd) {
      wrapperDOM.wHide();
      return;
    }
    const canHavePOSInCountry = countriesThatHavePOSEnabled.some(
      (country) => country === venueChannelCode,
    );
    wrapperDOM.wShow();

    if (App.config.get('venue').listedOnMarketplace) {
      wrapperDOM.html(
        Wahanda.Template.renderTemplate('looking-for-pos', {
          canHavePOSInCountry,
        }),
      );
      this.$('.js-pos-ad-link-info').click(CalendarAnalytics.trackLookingForPOSClick);
    }
  },

  renderSidebarInfo() {
    const sidebarInfoNode = this.$('#sidebar-info').get(0);
    App.ES6.Initializers.SidebarInfo({
      node: sidebarInfoNode,
      liveChatSource: 'calendar sidebar',
    }).render();
  },

  injectOptionsFromRouter() {
    const options = App.mainViewOptions || {};

    if (options.initialDate) {
      try {
        const date = Wahanda.Date.createDate(options.initialDate);
        App.trigger('calendar:date-change', date);
      } catch (e) {
        console.error(e);
      }
    }
    if (options.initialCalendarType) {
      this.changeType(options.initialCalendarType);
    }

    this.cleanMainViewOptions();
  },

  renderPaneView() {
    this.appointmentCalendar.headerPaneView.render();
  },

  renderResources() {
    const paneView = this.appointmentCalendar.headerPaneView;
    paneView.renderResources.apply(paneView, arguments);
  },

  changeType(type, options) {
    const previousTypeTheSame = this.calendarType === type;
    this.calendarType = type;
    this.typeSelector.setCurrentByType(type);
    this.storeCalendarType(type);
    App.Timer.reset('calendar-refresh');
    // Set the visible view's class
    this.$el.removeClass(this.allTypeClasses).addClass(`type-${type}`);
    App.trigger(
      Wahanda.Event.CALENDAR_TYPE_CHANGE,
      type,
      _.extend({ previousTypeTheSame }, options),
    );
  },

  getCalendarType() {
    if (this.calendarType) {
      return this.calendarType;
    }

    let type = this.getStoredCalendarType();
    if (!type) {
      type = 'appointment';
    }
    return type;
  },

  getActiveCalendarView() {
    if (this.getCalendarType() === 'appointment') {
      return this.appointmentCalendar;
    }
    return this.datedCalendar;
  },

  getStoredCalendarType() {
    const types = Wahanda.LocalStorage.get('calendar-type') || [];
    const venueId = App.getVenueId();

    const match = _.find(types, function (item) {
      return item.venueId === venueId;
    });

    return match ? match.type : null;
  },

  storeCalendarType(type) {
    const venueId = App.getVenueId();
    let newTypes = _.filter(Wahanda.LocalStorage.get('calendar-type') || [], function (item) {
      return item.venueId !== venueId;
    });

    newTypes.push({
      venueId,
      type,
    });

    // We don't want to store data for more than 5 venues
    if (newTypes.length > 5) {
      newTypes = newTypes.slice(newTypes.length - 5);
    }

    Wahanda.LocalStorage.set('calendar-type', newTypes);
  },

  // Events
  onTypeSelect(event, node) {
    const type = $(node).data('type');
    trackEvent('calendar', 'edit', 'type', type);

    this.changeType(type);
  },

  showVoucherRedemptionDialog(options) {
    App.Views.Calendar.VoucherRedemption.open(options);
  },

  openNewCheckout() {
    const dialog = new App.Views.Forms.POSDialog();
    dialog.render();
    dialog.open();
  },

  showSetAvailabilityDialog() {
    trackEvent('calendar', 'click', 'add-inventory');

    if (this.datedCalendar.isActive()) {
      this.datedCalendar.showSetAvailabilityDialog();
    }
  },

  closeOutSpaDays() {
    if (this.datedCalendar.isActive()) {
      this.datedCalendar.showCloseOutDaysDialog();
    }
  },

  onResourceChange(event) {
    App.trigger(Wahanda.Event.CALENDAR_RESOURCE_CHANGE, $(event.currentTarget).attr('id'));
  },

  // Main view URL hash functions
  /**
   * Updates the URL to the current state.
   */
  updateUrlWithCurrentOptions() {
    return this.getActiveCalendarView().updateUrlWithCurrentOptions();
  },

  getCurrentStateHash() {
    return this.getActiveCalendarView().getCurrentStateHash();
  },

  // Shortcuts

  setupShortcuts() {
    const onlyIfInputAccepted = function (fn) {
      return function () {
        if (this.navigationInputAccepted) {
          fn.apply(null, arguments);
        }
      }.bind(this);
    }.bind(this);

    const addShortcut = function (key, handlerFn) {
      Wahanda.Shortcuts.add(key, onlyIfInputAccepted(handlerFn.bind(this)));
    }.bind(this);

    const changeTypeToDay = function () {
      this.appointmentCalendar.trigger('change:type', 'day');
    }.bind(this);

    // Reload venue
    addShortcut('space', function () {
      App.trigger(Wahanda.Event.CALENDAR_REQUEST_REFRESH);
    });
    // Go to TODAY view
    addShortcut('backspace', function () {
      changeTypeToDay();
      App.trigger(Wahanda.Event.CALENDAR_GO_TODAY);
    });
    // Go back in calendar
    addShortcut('left', function () {
      App.trigger(Wahanda.Event.CALENDAR_GO_PREV);
    });
    // Go forward
    addShortcut('right', function () {
      App.trigger(Wahanda.Event.CALENDAR_GO_NEXT);
    });

    // 1..9 numbers changing resourceIds
    const numberHandler = function (event) {
      const resourcePosition = parseInt(String.fromCharCode(event.keyCode), 10);
      App.trigger('calendar:request-resource-view', resourcePosition);
    };

    for (let num = 1; num <= 10; num++) {
      addShortcut(String(num), numberHandler);
    }

    // Got to day view
    addShortcut('0', changeTypeToDay);

    const onlyIfAppointmentCalendar = function (fn) {
      return function () {
        if (this.getCalendarType() === 'appointment' && !this.floatingEventMode) {
          fn.call(this);
        }
      }.bind(this);
    }.bind(this);

    // New appointment. Only in Appointment mode.
    addShortcut(
      'a',
      onlyIfAppointmentCalendar(function () {
        this.getActiveCalendarView().openAddAppointmentDialog();
      }),
    );

    // New Block
    addShortcut(
      'b',
      onlyIfAppointmentCalendar(function () {
        this.appointmentCalendar.showBlockAddDialog(this.createCurrentCalEvent());
      }),
    );

    // Blockout mode
    addShortcut('m', onlyIfAppointmentCalendar(this.startMultipleBlocking));

    // Standalone checkout
    addShortcut(
      'c',
      onlyIfAppointmentCalendar(function () {
        if (App.config.get('venue').pointOfSaleEnabled) {
          this.openNewCheckout();
        }
      }),
    );

    // There are also some global shortcuts in `Wahanda.Shortcuts`.
  },

  lightweightAuthAction() {
    // We're in LightweightAuth mode. Probably will confirm an appointment.
    // Don't try refreshing after, as that will call out errors about calendar not yet initialized.
    this.appointmentCalendar.allowRefresh = false;
    this.openLightweightModeDialog();
    this.openDialogFromUrl();
  },

  openDialogFromUrl() {
    if (this.hasUrlDialog()) {
      const dialog = App.mainViewOptions.initialDialog;
      // Open dialogs from URL
      if (dialog.type === 'appointment' || dialog.type === 'appointment-order') {
        this.openAppointmentDialogFromUrl(dialog.type);
      }
      if (dialog.type === 'order') {
        this.openOrderDialogFromUrl();
      } else if (dialog.type === 'booking') {
        if (dialog.action === 'reject') {
          this.openBookingRejectionForm(dialog.id);
        } else {
          this.openBookingDialog(dialog.id);
        }
        delete App.mainViewOptions.initialDialog;
      }
    }
  },

  openAppointmentDialogFromUrl(type) {
    const self = this;
    const dialog = App.mainViewOptions.initialDialog;
    // If this is restricted mode, some models should be fetched separately
    let modelsLoaded = true;
    let orderLoaded = true;
    const models = [this.appointmentCalendar.model, this.appointmentCalendar.employeesCollection];
    let apptId = dialog.id;
    let apptGroupId;
    let orderToOpen;

    if (App.isRestrictedMode()) {
      modelsLoaded = false;
      self.appointmentCalendar.model.set('venueId', App.getVenueId());
      BackboneEx.Tool.MultiModelFetch.run(models, function () {
        modelsLoaded = true;
      });
    }
    if (type === 'appointment-order') {
      orderLoaded = false;
      const order = new App.Models.Order({ id: dialog.id });
      order.fetch().done(function () {
        const group = order.getPackageGroup();
        if (group) {
          apptGroupId = group.id;
          apptId = group.at(0).id;
        } else {
          // An order with no groups, probably either a single-appt order or already confirmed.
          orderToOpen = order;
        }
        if (dialog.action === 'confirm') {
          const onConfirmation = function () {
            orderLoaded = true;
            // Don't need to try to confirm the Appointment separately
            dialog.action = 'none';
          };
          let confirmTarget;

          if (group) {
            confirmTarget = group;
          } else if (order.isAppointment()) {
            confirmTarget = order.getAppointments()[0];
          } else {
            confirmTarget = order;
          }

          if (typeof confirmTarget.isUnconfirmed === 'function' && !confirmTarget.isUnconfirmed()) {
            // Appointment/ApptGroup already confirmed
            onConfirmation();
            return;
          }

          confirmTarget.confirm().done(onConfirmation);
        } else {
          orderLoaded = true;
        }
      });
    }
    // Doing this async, when the calendar or models are loaded
    Wahanda.Util.waitUntil(
      function () {
        return (
          orderLoaded &&
          ((App.isRestrictedMode() && modelsLoaded) || self.appointmentCalendar.rendered)
        );
      },
      function () {
        if (dialog.action === 'reject') {
          if (type === 'appointment-order') {
            self.appointmentCalendar.showAppointmentGroupRejectionForm(apptGroupId);
          } else {
            self.appointmentCalendar.showAppointmentRejectionForm(dialog.id);
          }
        } else if (orderToOpen) {
          self.appointmentCalendar.showAppointmentFromOrder(orderToOpen);
        } else {
          self.appointmentCalendar.showAppointmentFromUrl(apptId || dialog.id);
        }
        delete App.mainViewOptions.initialDialog;
      },
    );
  },

  openOrderDialogFromUrl() {
    const dialog = App.mainViewOptions.initialDialog;

    App.Views.Dialog.Order2.openById(dialog.id);

    delete App.mainViewOptions.initialDialog;
  },

  openAppointment(appointmentId) {
    this.appointmentCalendar.showAppointment(appointmentId);
  },

  hasUrlDialog() {
    return App.mainViewOptions && App.mainViewOptions.initialDialog;
  },

  openBookingDialog(bookingId) {
    const view = new App.Views.Dialog.Booking({
      model: new App.Models.Booking({
        id: bookingId,
        venueId: App.config.get('venue').id,
      }),
    });
    view.render();
    view.open();
  },

  openBookingRejectionForm(id) {
    const model = new App.Models.Booking({
      id,
      venueId: App.getVenueId(),
    });

    const openRejectionForm = function () {
      const form = new App.Views.Dialog.Booking.Rejection({
        model,
      });
      form.render();
      form.open();
    };

    model.fetch({
      success: openRejectionForm,
      error: BackboneEx.Tool.ModelLightweightFetch({
        onSuccess: openRejectionForm,
        onError: App.Views.Calendar.NoPermission.open,
      }),
      skipErrorHandling: true,
    });
  },

  checkCurrentCalendarType(allowed) {
    const current = this.getCalendarType();
    if (_.indexOf(allowed, current) === -1) {
      // Current visible type is not in the allowed types. Change to the first allowed.
      // Calendar should be rendered after this callback. Note the current flow!
      this.calendarType = allowed[0];
    }
  },
});

App.Views.Calendar.Appointment = {};
App.Views.Calendar.Dated = {};
