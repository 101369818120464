const environment = process.env.TW_ENV_TYPE || process.env.NODE_ENV || 'dev';
const isProduction = environment === 'prod' || environment === 'production';
const metricsServerPort = process.env.METRICS_SERVER_PORT || 10005;
const connectWebsocketsPort = process.env.CONNECT_WEBSOCKETS_PORT || 3050;
const isTest = process.env.NODE_ENV === 'test';
const segmentWriteKeyDEV = 'MDPs3JBRp9U3xv9ikZs1SAnBQbrGG7NS';
const segmentWriteKey = isProduction
  ? process.env.SEGMENT_WRITE_KEY
  : process.env.SEGMENT_WRITE_KEY || segmentWriteKeyDEV;

module.exports = {
  environment,
  isProduction,
  isTest,
  connectWebsocketsPort,
  metricsServerPort,
  segmentWriteKey,
};
