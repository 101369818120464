type AppendChildNodeProps = {
  id: string;
  parentNode: HTMLElement;
};

export const getOrAppendChild = ({ id, parentNode }: AppendChildNodeProps): HTMLElement => {
  const existingNode = document.getElementById(id);
  if (existingNode) {
    return existingNode;
  }

  const newNode = document.createElement('div');
  newNode.id = id;

  parentNode.appendChild(newNode);

  return newNode;
};
