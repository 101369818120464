import React, { ReactNode } from 'react';
import classnames from 'classnames';

import style from './SidebarLink.scss';

export interface SidebarLinkProps {
  dataTest?: string;
  href?: string;
  icon?: React.ReactNode;
  isVisible?: boolean;
  onClick?: () => void;
  isSelected: boolean;
  text: string;
  children?: ReactNode;
}

export class SidebarLink extends React.PureComponent<SidebarLinkProps> {
  public render() {
    const {
      dataTest,
      icon,
      href,
      onClick,
      isSelected,
      isVisible = true,
      text,
      children,
    } = this.props;

    const className = classnames(style.link, {
      [style.isSelected]: isSelected,
    });

    if (!isVisible) {
      return null;
    }

    return (
      <a
        {...(href ? { href } : {})}
        className={className}
        onClick={onClick}
        role="link"
        tabIndex={0}
        data-test={`sidebar-link${dataTest && `-${dataTest}`}`}
        data-selected={isSelected}
      >
        <span className={style.icon}>{icon && icon}</span>
        <span>{text}</span>
        {children}
      </a>
    );
  }
}
