import React from 'react';
import { equals, get, getIn, vector, curry, assocIn, assoc, toJs, map } from 'mori';
import { withAnyCursor, cursorValueHasChanged } from 'atom/cursors';
import { appState } from 'state';
import { enableES5 } from 'immer';
import openCloseDialog from 'components/reports/transactions/day-status/close-dialog';
import DragConfirmationDialog from 'components/calendar/drag-confirmation-dialog';
import SelectDropdown from 'components/common/SelectDropdown/Dropdown';
import MultiBlockoutMode from 'components/calendar/MultiBlockoutMode';
import CalendarEventEditor from 'components/calendar/CalendarEventEditor';
import DiscountRuleDialog from 'components/menu/DiscountRuleDialog/container';
import { KEY as DISCOUNT_EDITOR_KEY } from 'src/initializers/DiscountRuleDialog';
import { KEY as SELECT_SERVICE_DIALOG_KEY } from 'components/menu/SelectServiceDialog/initializer';

enableES5();

const POS_CLOSE_DAY = ['pos', 'close-day'];

function nullStateKey(key) {
  appState.swap(curry(assocIn, key, null));
}

function maybeGetDiscountRuleDialog(state) {
  const params = get(state, DISCOUNT_EDITOR_KEY);
  if (!params) {
    return null;
  }
  return <DiscountRuleDialog {...params} onClose={() => nullStateKey([DISCOUNT_EDITOR_KEY])} />;
}

function maybeGetDragConfirmationDialog(state) {
  const dialogData = get(state, 'calendar-drag-confirmation');
  if (!dialogData) {
    return null;
  }
  return (
    <DragConfirmationDialog
      {...toJs(dialogData)}
      onClose={(data) => {
        get(dialogData, 'onClose')(data);
        nullStateKey(['calendar-drag-confirmation']);
      }}
    />
  );
}

function maybeGetAllSelectDropdowns(state) {
  return map((item) => {
    const data = get(item, 1);
    if (!data) {
      return null;
    }
    const key = get(item, 0);
    return (
      <SelectDropdown
        key={key}
        selected={get(data, 'selected')}
        data={get(data, 'data')}
        open={get(data, 'open')}
        onSelect={get(data, 'onSelect')}
        // @ts-expect-error ts-migrate(2322) FIXME: Property 'onTypeahead' does not exist on type 'Int... Remove this comment to see the full error message
        onTypeahead={get(data, 'onTypeahead')}
        parentBoundingRect={get(data, 'parentBoundingRect')}
        isTypeahead={get(data, 'isTypeahead')}
        typeaheadResults={get(data, 'typeaheadResults')}
        typeaheadInput={get(data, 'typeaheadInput')}
        extraClassName={get(data, 'extraClassName')}
        optionExtraClassName={get(data, 'optionExtraClassName')}
      />
    );
  }, get(state, 'select-dropdown'));
}

function maybeGetMultiBlockoutMode(state) {
  const data = get(state, 'multi-blockout-mode');
  if (data) {
    const extCloseFn = get(data, 'onClose');
    const closeFn = (...params) => {
      nullStateKey(['multi-blockout-mode']);
      extCloseFn(...params);
    };
    return <MultiBlockoutMode {...toJs(data)} close={closeFn} />;
  }
  return null;
}

function maybeGetCalendarEditor(state) {
  const editorData = get(state, 'calendar-event-editor');
  if (editorData) {
    const onClose = get(editorData, 'onClose');
    return (
      <CalendarEventEditor
        editorData={toJs(editorData)}
        mediator={
          get(editorData, 'appointmentViewData')
            ? get(get(editorData, 'appointmentViewData'), 'mediator')
            : null
        }
        model={
          get(editorData, 'appointmentViewData')
            ? get(get(editorData, 'appointmentViewData'), 'model')
            : null
        }
        collection={
          get(editorData, 'appointmentViewData')
            ? get(get(editorData, 'appointmentViewData'), 'collection')
            : null
        }
        onClose={() => {
          // TODO: Find better solution...
          // Ensure all existing validation tooltips are removed
          const tooltips = document.getElementsByClassName('qtip');
          while (tooltips.length > 0) {
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            tooltips[0].parentNode.removeChild(tooltips[0]);
          }
          // Remove the data
          appState.swap((oldState) => assoc(oldState, 'calendar-event-editor', null));
          if (onClose) {
            onClose();
          }
        }}
      />
    );
  }
  return null;
}

export default class ReactGlobal extends React.Component<{}, {}> {
  forceUpdate: any;

  UNSAFE_componentWillMount() {
    // Watch for non-react rendering
    appState.addWatch(
      'react-global-nonReact',
      withAnyCursor(
        (oldState, newState) => {
          const newCloseDayData = getIn(newState, POS_CLOSE_DAY);
          if (!equals(newCloseDayData, getIn(oldState, POS_CLOSE_DAY)) && newCloseDayData) {
            // TODO: rebuild closeDayDialog into a non-jQuery-dialog
            // @ts-expect-error ts-migrate(2345) FIXME: Type '(params: any) => void' is not assignable to ... Remove this comment to see the full error message
            openCloseDialog(get(newCloseDayData, 'date'), (params) => {
              // Clear the state
              nullStateKey(POS_CLOSE_DAY);
              // Call the callback function, if any
              const fn = get(newCloseDayData, 'callback');
              if (typeof fn === 'function') {
                fn(params);
              }
            });
          }
        },
        vector(POS_CLOSE_DAY),
        cursorValueHasChanged,
      ),
    );
    // State changes which need re-rendering this component
    appState.addWatch(
      'react-global',
      withAnyCursor(
        () => this.forceUpdate(),
        vector(
          ['calendar-drag-confirmation'],
          ['select-dropdown'],
          ['multi-blockout-mode'],
          ['calendar-event-editor'],
          [DISCOUNT_EDITOR_KEY],
          [SELECT_SERVICE_DIALOG_KEY],
        ),
        cursorValueHasChanged,
      ),
    );
  }

  render() {
    const state = appState.deref();
    return (
      <div>
        {maybeGetDragConfirmationDialog(state)}
        {toJs(maybeGetAllSelectDropdowns(state))}
        {maybeGetMultiBlockoutMode(state)}
        {maybeGetCalendarEditor(state)}
        {maybeGetDiscountRuleDialog(state)}
      </div>
    );
  }
}
