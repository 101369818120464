import { ActionUnionTypes } from 'reduxStore/actionCreator';
import * as actions from './actions';
import { produce } from 'immer';
import {
  VENUE_PHOTOS_SUCCESS,
  VENUE_PHOTO_UPLOAD_REQUEST,
  VENUE_PHOTO_UPLOAD_SUCCESS,
  VENUE_PHOTO_REMOVE_REQUEST,
  VENUE_PHOTO_CROPPING_UPDATE_SUCCESS,
  VENUE_PHOTOS_ORDER_REQUEST,
  VENUE_PHOTO_UPLOAD_FAILURE,
  VENUE_PHOTO_CROPPING_UPDATE_REQUEST,
  VENUE_PHOTO_CROPPING_UPDATE_FAILURE,
} from 'reduxStore/actionsConst';

export type PhotoWithStatus = Photo & PhotoStatus;

export type Photo = {
  localId: string;
  id?: number;
  name?: string;
  uris?: Record<Dimension, string>;
  visible?: boolean;
  src?: string;
  originalUri?: string;
  cropping?: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
};

type PhotoStatus = {
  isLoading?: boolean;
  error?: string;
};

type Dimension = '99x66' | '120x80' | '156x104' | '198x132' | '240x160' | '312x208';

export interface PhotosReducer {
  photos: Array<PhotoWithStatus>;
}

export const initialState: PhotosReducer = {
  photos: [],
};

export const venueDetails = produce(
  (state: PhotosReducer = initialState, action: ActionUnionTypes<typeof actions>) => {
    switch (action.type) {
      case VENUE_PHOTOS_SUCCESS: {
        state.photos = action.payload.photos.map((photo: Photo) => ({
          ...photo,
          localId: window.crypto.randomUUID(),
        }));
        break;
      }
      case VENUE_PHOTO_UPLOAD_REQUEST: {
        const photoToUpload = action.payload.photo;
        state.photos.push({ ...photoToUpload, isLoading: true, error: undefined });
        break;
      }
      case VENUE_PHOTO_CROPPING_UPDATE_REQUEST: {
        const photo = state.photos.find((photo) => photo.localId === action.payload.localId);
        if (photo) {
          photo.isLoading = true;
          photo.error = undefined;
        }
        break;
      }
      case VENUE_PHOTO_CROPPING_UPDATE_FAILURE: {
        const photo = state.photos.find((photo) => photo.localId === action.payload.photo.localId);
        if (photo) {
          photo.isLoading = false;
          // When cropping fails, we don't want to show a permanent error,
          // just remove the loading state and display a generic notification
          photo.error = undefined;
        }
        break;
      }
      case VENUE_PHOTO_UPLOAD_SUCCESS:
      case VENUE_PHOTO_CROPPING_UPDATE_SUCCESS: {
        const photoUploaded = action.payload.photo;
        const photo = state.photos.find((photo) => photo.localId === photoUploaded.localId);
        if (photo) {
          Object.assign(photo, photoUploaded);
          photo.isLoading = false;
          photo.error = undefined;
        }
        break;
      }
      case VENUE_PHOTO_UPLOAD_FAILURE: {
        const photoFailed = action.payload.photo;
        const error = action.payload.error;
        const photo = state.photos.find((photo) => photo.localId === photoFailed.localId);
        if (photo) {
          photo.error = error;
          photo.isLoading = false;
        }
        break;
      }
      case VENUE_PHOTO_REMOVE_REQUEST: {
        state.photos = state.photos.filter(({ localId }) => localId !== action.payload.localId);
        break;
      }
      case VENUE_PHOTOS_ORDER_REQUEST: {
        const orderedPhotos = action.payload.photos;
        state.photos.sort(sorter(orderedPhotos));
        break;
      }
      default:
        break;
    }
  },
  initialState,
);

const sorter = (ordered: Array<{ localId: string }>) => (a: Photo, b: Photo) => {
  const aIndex = findIndexById(ordered, a);
  const bIndex = findIndexById(ordered, b);
  return aIndex - bIndex;
};

const findIndexById = (photos: Array<{ localId: string }>, photo: Photo) =>
  photos.findIndex(({ localId }) => photo.localId === localId);
