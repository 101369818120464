import React, { useState } from 'react';
import Dialog from 'components/common/react-dialog';
import Wahanda from 'common/wahanda';
import { BOOKING_ACTOR } from 'common/consts';
import { CancellationFlowAnalytics } from 'common/analytics';
import { DialogFooter, DialogFooterButtonProps } from 'components/common/DialogFooter';
import { ExplanationBullets } from '../../ExplanationBullets';
import { AppointmentInfoBoxes } from '../../AppointmentInfoBoxes';
import { buildExplanationBullets } from '../../ExplanationBullets/buildExplanationBullets';
import { ExplanationBulletPointTranslations } from '../../ExplanationBullets/explanationBulletTranslations';
import { RecurrenceCancellation } from '../RecurrenceCancellation/RecurrenceCancellation';

const lang = Wahanda.lang.calendar.appointments.cancellation.confirmCancellation;
interface Props {
  isReasonVenue: boolean;
  isPrepaid: boolean;
  bookingActor: BOOKING_ACTOR;
  isFirstTimeCustomer: boolean;
  isRecurring: boolean;
  onClose: () => void;
  onCancel: (params: { includeFutureRecurrences: boolean | null }) => void;
}

export const ConfirmCancellationWithBulletPoints = (props: Props) => {
  const {
    isReasonVenue = false,
    bookingActor,
    isPrepaid,
    isFirstTimeCustomer,
    onCancel,
    onClose,
    isRecurring,
  } = props;

  const [includeFutureRecurrences, setIncludeFutureRecurrences] = useState(false);

  const bulletPoints = buildExplanationBullets({
    flow: 'cancellation',
    bookingActor,
    isVenueLed: isReasonVenue,
    isPrepaid,
    isFirstTimeCustomer,
  }).map((key) => ExplanationBulletPointTranslations.get(key));

  const onSubmit = () => {
    onCancel({
      includeFutureRecurrences: isRecurring ? includeFutureRecurrences : null,
    });
  };

  const buttons: DialogFooterButtonProps[] = [
    {
      title: Wahanda.lang.shared.close,
      type: 'secondary',
      onClick: onClose,
    },
    {
      title: lang.buttons.cancel,
      type: 'alert',
      onClick: onSubmit,
      dataTest: 'cancellation-submit',
    },
  ];

  const onRecurrenceCancellationChange = (checked: boolean) => {
    CancellationFlowAnalytics.trackClickCancelFutureAppointments(checked);
    setIncludeFutureRecurrences(checked);
  };

  return (
    <Dialog
      dataTest="confirm-cancellation-with-bullet-modal"
      width={420}
      title={lang.title}
      onClose={onClose}
    >
      <AppointmentInfoBoxes isPrepaid={isPrepaid} bookingActor={bookingActor} />
      <ExplanationBullets bulletPoints={bulletPoints} />
      {isRecurring && <RecurrenceCancellation onChange={onRecurrenceCancellationChange} />}
      <DialogFooter fullWidth buttons={buttons} />
    </Dialog>
  );
};
