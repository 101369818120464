import React from 'react';
import ReactDOM from 'react-dom';
import { Initializer } from 'common/types/initializers';
import { Reconnect } from 'federation/Reconnect';
import Wahanda from 'common/wahanda';

const container = Wahanda.Dialogs.getReactContainer();

type FinalancellationProps = {
  step: 'finalConfirmation';
  bookingActor: 'CUSTOMER' | 'SUPPLIER' | 'SUPPLIERS_CUSTOMER';
  paymentType: 'PREPAID' | 'PAY_AT_VENUE';
  isFirstTimeCustomer: boolean;
  isPaymentProtected: boolean;
  customer?: {
    emailAddress: string | null;
    id: number;
    name: string;
    phone: string | null;
  };
  reschedulingAllowed: boolean;
  onClose: () => void;
  onReschedule: () => void;
  onCancel: () => void;
};

type RescheduleOrCancelProps = {
  step: 'rescheduleOrCancel';
  bookingActor: 'CUSTOMER' | 'SUPPLIER' | 'SUPPLIERS_CUSTOMER';
  isFirstTimeCustomer: boolean;
  reschedulingAllowed: boolean;
  onClose: () => void;
  onReschedule: () => void;
  onCancel: () => void;
};

export const CancellationInitializer = (
  props: FinalancellationProps | RescheduleOrCancelProps,
): Initializer => {
  const destroy = () => ReactDOM.unmountComponentAtNode(container);

  const render = () => {
    destroy();
    ReactDOM.render(
      <Reconnect
        name="AppointmentCancellation"
        props={{
          ...props,
        }}
      />,
      container,
    );
  };

  return { destroy, render };
};
