/**
 *  This module exports all modules which should be visible to the Backbone App
 */
import React from 'react';
import ReactDOM from 'react-dom';

import moment from 'common/moment';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';

import FloatingEventHandler from 'utilities/FloatingEventHandler';

/*
 *  Initializers
 */
import { SidebarInfoInitializer } from 'components/common/Sidebar/SidebarInfo/initializer';
import { CovidBannerInitializer } from 'components/CovidBanner/initializer';
import initClientReports from './initializers/ClientReports';
import initSalesReports from './initializers/SalesReports';
import { injectResourcesList, venueChangeInitiated } from './initializers/resources';
import { CheckoutSummaryDialogInitializer } from './initializers/checkout-summary-dialog';
import { DialogConfirmationInitializer } from './components/common/dialog/DialogConfirmation/initializer';
import initCalendarDropdowns from './initializers/calendar-dropdowns';
import SettingsOnlineWidget from './initializers/SettingsOnlineWidget';
import changeStateExternally from './initializers/StateChange';
import { initPOSSettings, canNavigateAway, unmountPOSSettings } from './initializers/posSettings';
import ReactGlobal from './initializers/ReactGlobal';
import {
  render as renderProductsTab,
  destroy as destroyProductsTab,
} from './initializers/ProductsTab';
import productDialog from './initializers/ProductDialog';
import { render as renderMenu, destroy as destroyMenu } from './initializers/Menu';
import {
  render as renderDiscountsTab,
  destroy as destroyDiscountsTab,
} from './initializers/DiscountsTab';
import posCheckoutCheck from './initializers/pos-checkout-check';
import { DialogCloseWithChangesInitializer } from './components/common/dialog/DialogCloseWithChanges/initializer';
import RebookingChangesDialog from './initializers/RebookingChanges';
import ErrorDialog from './initializers/ErrorDialog';
import showDiscountRuleDialog from './initializers/DiscountRuleDialog';
import renderDirectDebitBankDetailsChangeConfirmation from './initializers/DirectDebitBankDetailsChangeConfirmation';
import { SelectServiceDialogInitializer } from './components/menu/SelectServiceDialog/initializer';
import { OfferTypeChoiceDialogInitializer } from './components/menu/OfferTypeChoiceDialog/initializer';
import renderDiscountRuleMenuRow from './initializers/DiscountRuleMenuRow';
import {
  render as renderMenuPackagePricing,
  destroy as destroyMenuPackagePricing,
} from './initializers/PackagePricing';
import { NoticeInitializer } from './components/common/Notice/initializer';
import { DashboardInitializer } from './initializers/dashboard';
import { paymentsInitializer } from './components/settings/PaymentMessage/initializer';
import { AccountLanguageSelect } from './initializers/AccountLanguageSelect';
import notificationList from './initializers/NotificationList';
import notificationListHeader from './initializers/NotificationListHeader';
import { MarketingInitializer } from './initializers/marketing';
import externalSalonIntegration from './initializers/ExternalSalonIntegration';
import impressum from './initializers/Impressum';
import { CheckoutButtonInitializer } from './components/calendar/SidebarActions/CheckoutButton/initializer';
import clientsBulkDelete from './initializers/ClientsBulkDelete';
import { EmployeeDialogInitializer } from './initializers/EmployeeDialog';
import { LoginPageInitializer } from './components/login/initializer';
import { UnsupportedBrowserInitializer } from './components/shared/UnsupportedBrowser/initializer';

import { SelectInitializer } from './components/common/Select/initializer';
import { LayoutHeaderInitializer } from './components/common/Layout/LayoutHeader/initializer';
import { ReportsInitializer } from './components/reports/Reports/initializer';
import { CustomerFormDialogInitializer } from './components/customers/CustomerFormDialog/initializer';
import { PrepayRequireDialogInitializer } from './components/calendar/CalendarEventEditor/NoShow/PrepayRequireDialog/initializer';
import { NoShowOverPhoneInitializer } from './components/calendar/CalendarEventEditor/NoShow/NoShowOverPhone/initializer';
import { ClientsImport } from './components/clients/ClientsImport/initializer';
import { ShiftVenueModalInitializer } from './components/shifts/ShiftVenueModal/initializer';
import { ShiftEmployeeModalInitializer } from './components/shifts/ShiftEmployeeModal/initializer';
import { TeamLayoutInitializer } from './components/team/initializer';
import { DurationSelectorInitializer } from './components/shared/DurationSelector/initializer';
import { AppointmentRepeatRowInitializer } from './components/calendar/CalendarEventEditor/AppointmentRepeatRow/initializer';
import { PatchTestRequired } from './components/menu/PatchTestRequired/initializer';
import { EmployeeWorkingHoursToggleInitializer } from './components/calendar/EmployeeWorkingHoursToggle/initializer';
import { RootTransactionDialogInitializer } from './components/reports/transactions/RootTransactionDialog/initializer';
import { ResourceCheckWarningInitializer } from './components/calendar/CalendarEventEditor/ResourceCheckWarning/initializer';
import { MenuGroupModalInitializer } from './components/menu/MenuGroupModal/initializer';
import { AddServicesModalInitializer } from './components/menu/AddServicesModal/initializer';
import { PaymentProtectionBadgeInitializer } from './components/shared/PaymentProtectiomBadge/initializer';
import { ReceiptCopyDialogInitializer } from './components/pos/ReceiptCopyDialog/initializer';
import { IdentityVerificationModalInitializer } from './components/calendar/IdentityVerificationModal/initializer';
import { KycBannerInitializer } from 'components/KycBanner/initializer';
import { KycOldBannerInitializer } from 'components/KycBanner/oldInitializer';
import { OnboardingWidgetInitializer } from 'components/shared/OnboardingWidget/initializer';
import { OnboardingInitializer } from 'components/shared/Onboarding/initializer';
import { Tracking } from 'src/tracking';
import { CustomerFormLinksInitializer } from 'components/calendar/CustomerFormLinks/initializer';
import { PhotosGuideSidebarInitializer } from 'components/settings/PhotosGuideSidebar/initializer';
import { PhotoUploadInitializer } from 'components/common/PhotoUpload/initializer';
import { PhotoThumbnailListInitializer } from 'components/common/PhotoThumbnailList/initializer';
import { ImageEditDialogInitializer } from 'components/common/dialog/ImageEditDialog/initializer';
import { SalesforceBotInitializer } from 'common/salesforce-bot/initializer';
import { initUnsecureSalesforceBot } from 'common/salesforce-bot/index';
import { SetupProgressInitializer } from 'components/shared/SetupProgress/initializer';
import { CancellationInitializer } from 'components/calendar/CalendarEventEditor/Cancellation/initializer';
/*
 *  Export into the Backbone namespace
 */
if (!App.ES6.React) {
  App.ES6.React = React;
  App.ES6.ReactDOM = ReactDOM;
  App.ES6.moment = moment;
}

Wahanda.Tracking = Tracking;
Wahanda.FloatingEventHandler = FloatingEventHandler;
Wahanda.POSCheckoutCheck = posCheckoutCheck;
Wahanda.initUnsecureSalesforceBot = initUnsecureSalesforceBot;

App.ES6.Initializers.State = { change: changeStateExternally };
App.ES6.Initializers.ReactGlobal = ReactGlobal;

// Common
App.ES6.Initializers.Notice = NoticeInitializer;
App.ES6.Initializers.ConfirmationDialog = DialogConfirmationInitializer;
App.ES6.Initializers.DialogCloseWithChanges = DialogCloseWithChangesInitializer;
App.ES6.Initializers.ErrorListDialog = ErrorDialog;
App.ES6.Initializers.LayoutHeader = LayoutHeaderInitializer;
App.ES6.Initializers.Select = SelectInitializer;
App.ES6.Initializers.CheckoutButton = CheckoutButtonInitializer;
App.ES6.Initializers.SidebarInfo = SidebarInfoInitializer;
App.ES6.Initializers.CovidBanner = CovidBannerInitializer;
App.ES6.Initializers.LoginPage = LoginPageInitializer;
App.ES6.Initializers.UnsupportedBrowser = UnsupportedBrowserInitializer;
App.ES6.Initializers.IdentityVerificationModal = IdentityVerificationModalInitializer;
App.ES6.Initializers.ImageEditDialogInitializer = ImageEditDialogInitializer;
App.ES6.Initializers.SalesforceBotInitializer = SalesforceBotInitializer;

// Shared
App.ES6.Initializers.AccountLanguageSelect = AccountLanguageSelect;
App.ES6.Initializers.CustomerFormDialog = CustomerFormDialogInitializer;
App.ES6.Initializers.KycBanner = KycBannerInitializer;
App.ES6.Initializers.OldKycBanner = KycOldBannerInitializer;

// Calendar
App.ES6.Initializers.CalendarDropdowns = { initialize: initCalendarDropdowns };
App.ES6.Initializers.DurationSelector = DurationSelectorInitializer;
App.ES6.Initializers.AppointmentRepeatRow = AppointmentRepeatRowInitializer;
App.ES6.Initializers.EmployeeWorkingHoursToggle = EmployeeWorkingHoursToggleInitializer;
App.ES6.Initializers.NoShowOverPhoneDialog = NoShowOverPhoneInitializer;
App.ES6.Initializers.PrepayRequireDialog = PrepayRequireDialogInitializer;
App.ES6.Initializers.RebookingChangesDialog = RebookingChangesDialog;
App.ES6.Initializers.CustomerFormLinks = CustomerFormLinksInitializer;
App.ES6.Initializers.ResourceCheckWarning = ResourceCheckWarningInitializer;
App.ES6.Initializers.Cancellation = CancellationInitializer;

// Clients
App.ES6.Initializers.ClientsBulkDelete = clientsBulkDelete;
App.ES6.Initializers.ClientsImport = ClientsImport;

// Dashboard
App.ES6.Initializers.Dashboard = DashboardInitializer;

// Marketing
App.ES6.Initializers.Marketing = MarketingInitializer;
App.ES6.Initializers.Impressum = impressum;

// Menu
App.ES6.Initializers.Menu = { render: renderMenu, destroy: destroyMenu };
App.ES6.Initializers.DiscountsTab = {
  render: renderDiscountsTab,
  destroy: destroyDiscountsTab,
};
App.ES6.Initializers.MenuGroupModal = MenuGroupModalInitializer;
App.ES6.Initializers.AddServicesModal = AddServicesModalInitializer;
App.ES6.Initializers.menuOfferPackagePricing = {
  render: renderMenuPackagePricing,
  destroy: destroyMenuPackagePricing,
};
App.ES6.Initializers.OfferTypeChoiceDialog = OfferTypeChoiceDialogInitializer;
App.ES6.Initializers.PatchTestRequired = PatchTestRequired;
App.ES6.Initializers.renderDiscountRuleMenuRow = renderDiscountRuleMenuRow;
App.ES6.Initializers.SelectServiceDialog = SelectServiceDialogInitializer;
App.ES6.Initializers.showGroupDiscountEditor = showDiscountRuleDialog;

// Notification
App.ES6.Initializers.NotificationsList = notificationList;
App.ES6.Initializers.NotificationsListHeader = notificationListHeader;

// POS
App.ES6.Initializers.POSSettings = {
  inject: initPOSSettings,
  canNavigateAway,
  unmountPOSSettings,
};
App.ES6.Initializers.PaymentMessage = paymentsInitializer;
App.ES6.Initializers.ReceiptCopyDialog = ReceiptCopyDialogInitializer;

// Products
App.ES6.Initializers.ProductsTab = {
  render: renderProductsTab,
  destroy: destroyProductsTab,
};
App.ES6.Initializers.ProductDialog = productDialog;

// Reports
App.ES6.Initializers.Reports = ReportsInitializer;
App.ES6.Initializers.CheckoutSummaryDialog = CheckoutSummaryDialogInitializer;
App.ES6.Initializers.clientReports = initClientReports;
App.ES6.Initializers.RootTransactionDialogInitializer = RootTransactionDialogInitializer;
App.ES6.Initializers.salesReports = initSalesReports;

// Team
App.ES6.Initializers.Team = TeamLayoutInitializer;
App.ES6.Initializers.EmployeeDialog = EmployeeDialogInitializer;
App.ES6.Initializers.ShiftVenueModal = ShiftVenueModalInitializer;
App.ES6.Initializers.ShiftEmployeeModal = ShiftEmployeeModalInitializer;
App.ES6.Initializers.ShiftVenueModal = ShiftVenueModalInitializer;
App.ES6.Initializers.ShiftEmployeeModal = ShiftEmployeeModalInitializer;

// Settings
App.ES6.Initializers.ExternalSalonIntegration = externalSalonIntegration;
App.ES6.Initializers.renderDirectDebitBankDetailsChangeConfirmation = renderDirectDebitBankDetailsChangeConfirmation;
App.ES6.Initializers.Resources = {
  inject: injectResourcesList,
  venueChangeInitiated,
};
App.ES6.Initializers.SettingsOnlineWidget = SettingsOnlineWidget;
App.ES6.Initializers.OnboardingWidget = OnboardingWidgetInitializer;
App.ES6.Initializers.Onboarding = OnboardingInitializer;
App.ES6.Initializers.SetupProgress = SetupProgressInitializer;
App.ES6.Initializers.PaymentProtectionBadge = PaymentProtectionBadgeInitializer;
App.ES6.Initializers.PhotosGuideSidebar = PhotosGuideSidebarInitializer;
App.ES6.Initializers.PhotoUpload = PhotoUploadInitializer;
App.ES6.Initializers.PhotoThumbnailList = PhotoThumbnailListInitializer;
