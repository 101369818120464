import { Photo } from 'components/settings/VenueDetails/reducer';
import { createAction } from 'reduxStore/actionCreator';
import {
  VENUE_PHOTOS_SUCCESS,
  UPLOAD_VENUE_PHOTOS_REQUEST,
  VENUE_PHOTO_UPLOAD_REQUEST,
  VENUE_PHOTO_UPLOAD_SUCCESS,
  VENUE_PHOTO_UPLOAD_FAILURE,
  VENUE_PHOTO_REMOVE_REQUEST,
  VENUE_PHOTOS_ORDER_REQUEST,
  VENUE_PHOTO_CROPPING_UPDATE_REQUEST,
  VENUE_PHOTO_CROPPING_UPDATE_SUCCESS,
  VENUE_PHOTO_CROPPING_UPDATE_FAILURE,
} from 'reduxStore/actionsConst';

export const venuePhotosSuccess = ({ photos }: { photos: Photo[] }) =>
  createAction(VENUE_PHOTOS_SUCCESS, { photos });

export const venuePhotosUploadRequest = ({ venueId, files }: { venueId: string; files: File[] }) =>
  createAction(UPLOAD_VENUE_PHOTOS_REQUEST, { venueId, files });

export type VenuePhotosUploadRequestAction = ReturnType<typeof venuePhotosUploadRequest>;

export const venuePhotoUploadRequest = ({ photo }: { photo: Photo }) =>
  createAction(VENUE_PHOTO_UPLOAD_REQUEST, { photo });

export const venuePhotoUploadSuccess = ({ photo }: { photo: Photo }) =>
  createAction(VENUE_PHOTO_UPLOAD_SUCCESS, { photo });

export const venuePhotoUploadFailure = ({ photo, error }: { photo: Photo; error: string }) =>
  createAction(VENUE_PHOTO_UPLOAD_FAILURE, { photo, error });

export const venuePhotoRemoveRequest = ({ localId }: { localId: string }) =>
  createAction(VENUE_PHOTO_REMOVE_REQUEST, { localId });

export type VenuePhotoRemoveRequestAction = ReturnType<typeof venuePhotoRemoveRequest>;

export const venuePhotosOrderRequest = ({ photos }: { photos: Array<{ localId: string }> }) =>
  createAction(VENUE_PHOTOS_ORDER_REQUEST, { photos });

export type VenuePhotosOrderRequestAction = ReturnType<typeof venuePhotosOrderRequest>;

export const venuePhotoCroppingUpdateRequest = ({
  venueId,
  localId,
  sourceImageId,
  cropping,
}: {
  venueId: string;
  localId: string;
  sourceImageId: number;
  cropping: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
}) =>
  createAction(VENUE_PHOTO_CROPPING_UPDATE_REQUEST, { venueId, localId, sourceImageId, cropping });

export type VenuePhotoCroppingUpdateRequestAction = ReturnType<
  typeof venuePhotoCroppingUpdateRequest
>;

export const venuePhotoCroppingUpdateSuccess = ({ photo }: { photo: Photo }) =>
  createAction(VENUE_PHOTO_CROPPING_UPDATE_SUCCESS, { photo });

export type VenuePhotoCroppingUpdateSuccessAction = ReturnType<
  typeof venuePhotoCroppingUpdateSuccess
>;

export const venuePhotoCroppingUpdateFailure = ({
  photo,
  error,
}: {
  photo: Photo;
  error: string;
}) => createAction(VENUE_PHOTO_CROPPING_UPDATE_FAILURE, { photo, error });

export type VenuePhotoCroppingUpdateFailureAction = ReturnType<
  typeof venuePhotoCroppingUpdateFailure
>;
