export const isDashboardEnabled = async (): Promise<boolean> => {
  const isAdministratorRole = App.isAdministrator() || App.isMasquerading();
  if (!isAdministratorRole) {
    return false;
  }

  const venueId = App.getVenueId();
  const url = `/api/self-sign-up/venue/${venueId}/checklists`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }

  const data = await response.json();

  const selfSetupDashboard = data?.checklists?.find(({ type }) => type === 'SELF_SETUP_DASHBOARD');

  return Boolean(selfSetupDashboard);
};
