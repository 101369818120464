import React from 'react';
import classnames from 'classnames';

import { SidebarInfo } from '../SidebarInfo';
import { SidebarLink, SidebarLinkProps } from '../SidebarLink';

import style from './Sidebar.scss';
import { OnboardingWidget } from 'components/shared/OnboardingWidget/OnboardingWidget';
import { ChatSourceType } from 'src/tracking-container/events/chat';
import { useLegacyOnboardingWidget } from 'components/common/Sidebar/Sidebar/useLegacyOnboardingWidget';

interface Props {
  links?: SidebarLinkProps[];
  children?: React.ReactNode;
  classes?: string;
  liveChatSource: ChatSourceType;
  hidden?: boolean;
}

export const Sidebar: React.FC<Props> = ({
  children,
  classes,
  links = [],
  liveChatSource,
  hidden,
}) => {
  const { isLegacyOnboardingEnabled, isLoading } = useLegacyOnboardingWidget();

  if (hidden || isLoading) {
    return null;
  }

  return (
    <div className={classnames(style.sidebar, [classes])} data-test="sidebar">
      {isLegacyOnboardingEnabled ? (
        <OnboardingWidget />
      ) : (
        <>
          <div className={style.links}>
            {links.length > 0
              ? links.map((link) => <SidebarLink {...link} key={link.dataTest} />)
              : children}
          </div>
          <SidebarInfo liveChatSource={liveChatSource} />
        </>
      )}
    </div>
  );
};
