import { isOnboardingWidgetEnabled } from 'components/shared/OnboardingWidget/utils/isOnboardingWidgetEnabled';
import { useEffect, useState } from 'react';

export const useLegacyOnboardingWidget = () => {
  const [isLegacyOnboardingEnabled, setIsLegacyOnboardingEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkOnboardingStatus = () =>
      isOnboardingWidgetEnabled()
        .then(setIsLegacyOnboardingEnabled)
        .finally(() => setIsLoading(false));

    checkOnboardingStatus();
  }, []);

  return { isLegacyOnboardingEnabled, isLoading };
};
