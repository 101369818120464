import React from 'react';
import classnames from 'classnames';
import App from 'common/backbone-app';
import Wahanda from 'common/wahanda';
import { enableRecording } from 'common/analytics';
import { NavigationAnalytics, L2Page, mapPageToL2Enum } from 'common/tracking/navigation';

import { canDelegateToApp, delegateToApp, ACTION } from 'common/app-delegation';
import { Sidebar, SidebarLink } from 'components/common/Sidebar';
import VenueInfo from 'components/dashboard/VenueInfo';
import VenueStats from 'components/dashboard/VenueStats/container';
import getUriByDimension from 'common/image-helpers';
import { WebsiteSettings, Chart, Goals as GoalsIcon, Star } from 'components/common/Icon';
import { Goals } from './Goals';
import { Reviews } from './Reviews';
import style from './style.scss';
import { Reconnect } from 'src/federation/Reconnect';
import { isDashboardEnabled } from 'components/shared/OnboardingWidget/utils/isDashboardEnabled';

const SETUP_TAB = L2Page.SETUP;
const STATS_TAB = L2Page.STATS;
const GOALS_TAB = L2Page.GOALS;
const REVIEWS_TAB = L2Page.CUSTOMER_REVIEWS;
const DEFAULT_TAB = STATS_TAB;
const VALID_TABS = [SETUP_TAB, STATS_TAB, GOALS_TAB, REVIEWS_TAB];
const LIVE_CHAT_SOURCE = 'dashboard sidebar';
const LANG = Wahanda.lang.dashboard;

interface IDashboardProps extends React.HTMLAttributes<Element> {
  isMasquerading: boolean;
  currentTab: string;
  actions: {
    requestVenueStatisticsAction: (...args: any[]) => any;
    requestSupplierDetailsAction: (...args: any[]) => any;
    requestCustomerReviewsAction: (...args: any[]) => any;
    submitResponseToCustomerReview: (...args: any[]) => any;
  };
  venueId: number;
  supplierId: number;
  venueStatistics: {
    reviews: {
      reviewCount: number;
      weightedRating: number;
    };
  };
  supplierDetails?: {
    bankAccount?: {};
  };
  goalsAllowed: boolean;
  venueImage?: {
    uris?: {};
  };
  marketplaceLink?: string;
  widgetLink?: string;
  imageEditingEnabled: boolean;
  canAccessSettings?: boolean;
  canViewFinanceData?: boolean;
  customerReviews?: {
    reviews?: any[];
  };
  reviewResponseSaving?: boolean;
  reviewResponseErrors?: {}[];
  isFetchingCustomerReviews?: boolean;
  canRedeemEvouchers?: boolean;
  bankAccount?: any;
  requestSupplierDetailsAction?: any;
  requestVenueStatisticsAction?: any;
  weightedRating?: any;
  reviews?: any;
  reviewCount?: any;
  uris?: any;
}

interface DashboardState {
  currentTab?: any;
  isSetUpEnabled: boolean;
  isLoading: boolean;
}

export class Dashboard extends React.Component<IDashboardProps, DashboardState> {
  private contentWrapper: HTMLDivElement | null = null;

  static defaultProps = {
    venue: {},
    customerReviews: [],
    canAccessSettings: null,
    canViewFinanceData: null,
    widgetLink: null,
    marketplaceLink: null,
    venueImage: null,
    venueStatistics: null,
    supplierDetails: null,
    canRedeemEvouchers: null,
    reviewResponseErrors: null,
    reviewResponseSaving: false,
    isFetchingCustomerReviews: false,
    currentTab: DEFAULT_TAB,
  } as any;

  state = {
    currentTab: this.getCurrentTab(this.props.currentTab),
    isSetUpEnabled: false,
    isLoading: true,
  };

  public componentDidMount() {
    NavigationAnalytics.trackPageView(mapPageToL2Enum(this.props.currentTab));
    enableRecording();
    this.props.actions.requestVenueStatisticsAction(this.props.venueId);
    this.changeHash(this.getCurrentTab(this.props.currentTab));
    isDashboardEnabled()
      .then((isEnabled) => {
        if (isEnabled) {
          this.setState({ isSetUpEnabled: true });
          this.changeHash(SETUP_TAB);
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentTab !== this.props.currentTab) {
      const currentTab = this.getCurrentTab(nextProps.currentTab);
      this.setState({ currentTab });
      this.changeHash(currentTab);
    }
  }

  onReviewsRouteClicked = () => {
    NavigationAnalytics.trackSidebarClick(L2Page.CUSTOMER_REVIEWS);
    if (canDelegateToApp(ACTION.REVIEW)) {
      delegateToApp(ACTION.REVIEW);
    } else {
      this.changeHash(REVIEWS_TAB);
    }
  };

  onSetUpRouteClicked = () => {
    NavigationAnalytics.trackSidebarClick(L2Page.SETUP);
    this.changeHash(SETUP_TAB);
  };

  onStatsRouteClicked = () => {
    NavigationAnalytics.trackSidebarClick(L2Page.STATS);
    this.changeHash(STATS_TAB);
  };

  onGoalsRouteClicked = () => {
    NavigationAnalytics.trackSidebarClick(L2Page.GOALS);
    this.changeHash(GOALS_TAB);
  };

  getCurrentTab(path) {
    if (!path) {
      return DEFAULT_TAB;
    }
    const pathInLower = path.toLowerCase();
    if (GOALS_TAB === pathInLower && !this.props.goalsAllowed) {
      return DEFAULT_TAB;
    }
    if (SETUP_TAB === pathInLower && !this.state?.isSetUpEnabled) {
      return DEFAULT_TAB;
    }
    if (VALID_TABS.indexOf(pathInLower) === -1) {
      return DEFAULT_TAB;
    }
    return pathInLower;
  }

  getVenueImageUrl() {
    if (this.props.venueImage) {
      return getUriByDimension(this.props.venueImage.uris, 122, 80);
    }
    return null;
  }

  changeHash = (path) => {
    const newHash = `venue/${this.props.venueId}/${path}`;
    window.location.hash = newHash;
  };

  openMarketplace() {
    window.open(this.props.marketplaceLink, '_blank');
  }

  openWidget() {
    if (this.props.widgetLink) {
      window.open(
        this.props.widgetLink,
        '',
        'width=970,height=640,resizable=0,scrollbars=yes,menubar=0,status=0,directories=0,toolbar=0,location=0',
      );
    } else {
      window.location.href = Wahanda.Url.getFullUrl(
        'settings',
        'online-booking/online-booking-widget',
      );
    }
  }

  /*
   *  TODO:
   *
   *  Refactor naming/const declarations, move into module function
   */
  redeemEvoucherCallback() {
    // eslint-disable-line
    const evoucherForm = new App.Views.Calendar.VoucherRedemption();
    evoucherForm.render();
    evoucherForm.open();
  }

  isCurrentPage(page) {
    return this.state.currentTab === page;
  }

  renderSetUp() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <Reconnect
        name="SetupPage"
        props={{
          onAction: this.onSetupAction,
          onToDoAction: this.onToDoAction,
        }}
      />
    );
  }

  onSetupAction = (taskItem: TaskItem) => {
    switch (taskItem) {
      case 'OPENING_HOURS':
        this.redirectTo('settings', 'venue-settings/opening-hours');
        break;
      case 'TEAM':
        this.redirectTo('team', 'team');
        break;
      case 'SERVICES':
        this.redirectTo('menu', 'services');
        break;
      case 'BANK_DETAILS':
        if (localStorage) {
          localStorage.setItem('FINANCE_SECTION_TO_OPEN', 'BANK_DETAILS');
        }
        this.redirectTo('settings', 'finance/settings');
        break;
      case 'TAX_ID':
        if (localStorage) {
          localStorage.setItem('FINANCE_SECTION_TO_OPEN', 'BUSINESS_DETAILS');
        }
        this.redirectTo('settings', 'finance/settings');
        break;
      case 'SALON_PHOTOS':
        this.redirectTo('settings', 'venue-settings/venue-details');
        break;
      case 'KYC':
        this.redirectToIdentityVerification();
        break;
      default:
        break;
    }
  };

  onToDoAction = (item: ToDoItem) => {
    switch (item) {
      case 'SERVICES':
        this.redirectTo('menu', 'services');
        break;
      case 'ROTA':
        this.redirectTo('team', 'rota');
        break;
      default:
        break;
    }
  };

  redirectTo(module: string, path?: string) {
    window.location = Wahanda.Url.getFullUrl(module, path);
  }

  redirectToIdentityVerification() {
    const returnUrl = encodeURIComponent(window.location.href);
    const url = `./identity-verification?returnUrl=${returnUrl}`;
    window.open(url, '_blank');
  }

  renderReviews() {
    if (!this.props.venueStatistics) {
      return null;
    }

    return (
      <Reviews
        actions={this.props.actions}
        venueId={this.props.venueId}
        customerReviews={this.props.customerReviews}
        reviewCount={this.props.venueStatistics.reviews.reviewCount}
        weightedRating={this.props.venueStatistics.reviews.weightedRating}
        reviewResponseSaving={this.props.reviewResponseSaving}
        reviewResponseErrors={this.props.reviewResponseErrors}
        isFetchingCustomerReviews={this.props.isFetchingCustomerReviews}
        scrollableParent={this.contentWrapper}
        isMasquerading={this.props.isMasquerading}
      />
    );
  }

  renderGoals() {
    if (!this.props.venueStatistics) {
      return null;
    }

    return (
      <Goals
        {...this.props.venueStatistics}
        requestVenueStatisticsAction={this.props.actions.requestVenueStatisticsAction}
        requestSupplierDetailsAction={this.props.actions.requestSupplierDetailsAction}
        bankAccount={this.props.supplierDetails?.bankAccount}
        venueId={this.props.venueId}
        supplierId={this.props.supplierId}
      />
    );
  }

  renderVenue() {
    if (!this.props.venueStatistics) {
      return null;
    }

    const redeemEvoucherCallback = this.props.canRedeemEvouchers
      ? () => this.redeemEvoucherCallback()
      : null;
    const openMarketplace = this.props.marketplaceLink ? () => this.openMarketplace() : null;
    const venueInfoProps = {
      openMarketplace,
      redeemEvoucherCallback,
      openWidget: () => this.openWidget(),
      redirectToImageEditing: () => {
        if (this.props.canAccessSettings && this.props.imageEditingEnabled) {
          window.location = Wahanda.Url.getFullUrl('settings');
        }
      },
      lang: Wahanda.lang.dashboard.venueInfo,
      imageUrl: this.getVenueImageUrl(),
    };
    return (
      <div>
        {/* @ts-expect-error */}
        <VenueInfo {...this.props} {...venueInfoProps} />
        {this.props.canViewFinanceData ? <VenueStats /> : null}
      </div>
    );
  }

  renderTab() {
    switch (this.state.currentTab) {
      case SETUP_TAB:
        return this.renderSetUp();
      case REVIEWS_TAB:
        return this.renderReviews();
      case GOALS_TAB:
        return this.renderGoals();
      case STATS_TAB:
        return this.renderVenue();
      default:
        return <></>;
    }
  }

  render() {
    return (
      <div className={style.dashboard}>
        <Sidebar classes={style.sidebar} liveChatSource={LIVE_CHAT_SOURCE}>
          <div>
            {!this.state.isLoading && (
              <>
                {this.state.isSetUpEnabled && (
                  <SidebarLink
                    icon={<WebsiteSettings />}
                    dataTest={LANG.pages.setup.toLowerCase()}
                    text={LANG.pages.setup}
                    onClick={this.onSetUpRouteClicked}
                    isSelected={this.isCurrentPage(SETUP_TAB)}
                  />
                )}
                <SidebarLink
                  icon={<Chart />}
                  text={LANG.pages.stats}
                  onClick={this.onStatsRouteClicked}
                  isSelected={this.isCurrentPage(STATS_TAB)}
                />
                {this.props.goalsAllowed && (
                  <SidebarLink
                    icon={<GoalsIcon />}
                    isSelected={this.isCurrentPage(GOALS_TAB)}
                    onClick={this.onGoalsRouteClicked}
                    text={LANG.pages.goals}
                  />
                )}
                <SidebarLink
                  icon={<Star />}
                  isSelected={this.isCurrentPage(REVIEWS_TAB)}
                  onClick={this.onReviewsRouteClicked}
                  text={LANG.pages.reviews}
                />
              </>
            )}
          </div>
        </Sidebar>
        <div
          className={style.contentWrapper}
          ref={(contentWrapper) => {
            this.contentWrapper = contentWrapper;
          }}
        >
          <div
            className={classnames(
              style.content,
              this.isCurrentPage(REVIEWS_TAB) && style.reviewsContent,
            )}
          >
            {!this.state.isLoading && this.renderTab()}
          </div>
        </div>
      </div>
    );
  }
}
